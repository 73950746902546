import React, {useEffect} from "react";
import Login from "./components/login/Login";
import Main from "./components/maincomponents/Main";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./RequireAuth";
/***********************************
 * App Component
 ***********************************/
function App() {

  useEffect(() => {
    // Disable right-click using useEffect hook
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);


  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/main"
          element={
            <RequireAuth>
              <Main />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
}

export default App;
