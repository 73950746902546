import { Enums } from "../enum/enums";

export default function LocationHerf() {
  const home = Enums.HOME; // "/"

  function onSetLocation() {
    window.location.href = home;
  }

  function onGetLocation() {
    return onSetLocation();
  }
  return {
    onGetLocation,
  };
}
